import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Thanks = () => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".post-content-body",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.5 }
    )
  }, [])

  return (
    <Layout>
      <SEO
        title="お問い合わせ"
        description="しょうのまきのホームページのお問い合わせ完了のページです。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />
      <header className="page-head">
        <Title title="メッセージは送信されました。" />
      </header>

      <article className="post-content post-content-width">
        <div className="post-content-body center">
          <p>
            お問い合わせありがとうございます。<br></br>
            いただいたメールの内容を確認後、通常3営業日以内に担当者よりご連絡をさせていただきます。
          </p>
        </div>
      </article>
    </Layout>
  )
}
export default Thanks
